/* Tailwind base add some css that conflict with Material UI components */
/* So we removed the file and added ours instead base on the tailwind file */
/* @tailwind base; */
@import "./preflight.css";

/* Rest of tailwind CSS */
@tailwind components;
@tailwind utilities;

/*
 * Globals
 */
html,
body,
#__next {
  @apply h-full bg-gray-50 text-gray-900;
  -webkit-font-smoothing: antialiased;
}

/*
 * Turn off number input spinners
 */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  @apply m-0;
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
 * Disable button appearence (Safari)
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}

/*
 * Allows hiding some scrollbars
 */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/*
 * NProgress
 */
@import "../../node_modules/nprogress/nprogress.css";

/* Display at the bottom of navbar */
#nprogress .bar {
  background-color: #319e8b !important;
  top: 0px !important;
  height: 3px !important;
}

#nprogress .peg {
  display: none !important;
}

/*
 * Text-shadow
 */
.text-shadow {
  text-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.hide-scrollbar {
  scrollbar-width: none;
}

/*
 * MDX Editor dialogs
 */
[class^="_primaryButton"],
[class^="_secondaryButton"] {
  border: 1px solid #e5ebea !important;
  color: #112723 !important;
}

/*
 * Antd menus dropdowns
 */
.ant-cascader-menu {
  height: max(200px, 40vh) !important;
  min-width: 16rem !important;
}
.ant-cascader,
.ant-select-selector {
  min-height: 40px !important;
}

.ant-select-sm.ant-cascader {
  min-height: 28px !important;
}
.ant-select-sm .ant-select-selector {
  min-height: 28px !important;
}
.ant-select-sm .ant-select-selection-item {
  font-size: 14px !important;
}

.ant-cascader-checkbox {
  align-self: flex-start !important;
  margin-top: 3px !important;
}
.ant-select-selection-overflow-item > span {
  max-width: 100%;
}
.ant-cascader-menu-item[data-path-key="best-match"] {
  display: none;
}
.ant-cascader-menu-item[data-path-key="custom-jobs"] > .ant-cascader-checkbox {
  display: none;
}
